<template>
    <div>
        <page-title :heading="$t('customers.lang_cust_search') " :permissionAdd="this.$store.getters['permissions/checkPermission']('create_cust')" show-add-button url-add="/customer/createCustomer" :subheading="$t('customers.lang_cust_search')" :icon=icon></page-title>
        <div class="app-main__inner">
        <SearchCustomer></SearchCustomer>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../Layout/Components/PageTitle.vue";
    import SearchCustomer from "../../components/customers/SearchCustomers";

    export default {
        components: {
            PageTitle,
            SearchCustomer
        },
        data: () => ({
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>